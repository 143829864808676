import { useTranslation } from "react-i18next";
import { Button, Nav, NavItem, Navbar, NavbarBrand } from "reactstrap";
import ValluLogo from "../images/_Vallu_musta.svg";

export default function HeaderComponent() {
  const { i18n, t } = useTranslation("common");

  const changeLanguage = (lang = "fi") => {
    i18n.changeLanguage(lang);
    document.documentElement.lang = lang;
  };

  return (
    <Navbar className="App-header">
      <h1>
        <NavbarBrand href="/" aria-label={t("logoLink")}>
          <img
            alt="Vallu logo"
            src={ValluLogo}
            className="img-fluid"
            style={{ height: "3.5rem" }}
          />
        </NavbarBrand>
      </h1>
      <Nav>
        <NavItem>
          <Button
            color="link"
            className="text-decoration-none fw-bold"
            onClick={() => changeLanguage("fi")}>
            Suomeksi
          </Button>
        </NavItem>
        <NavItem>
          <Button
            color="link"
            className="text-decoration-none fw-bold"
            onClick={() => changeLanguage("sv")}>
            På svenska
          </Button>
        </NavItem>
      </Nav>
    </Navbar>
  );
}
