import { Suspense } from "react";
import "./App.css";
import Router from "./Router";
import HeaderComponent from "./components/HeaderComponent";

export default function App() {
  return (
    <Suspense fallback="loading" className="App">
      <HeaderComponent />
      <Router />
    </Suspense>
  );
}
