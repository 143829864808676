import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import fi_translations from "./locales/fi/translation.json";
import sv_translations from "./locales/sv/translation.json";
import 'bootstrap/dist/css/bootstrap.min.css';

i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'fi',                              // language to use
    resources: {
        fi: {
            common: fi_translations
        },
        sv: {
            common: sv_translations
        }
    },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
  </React.StrictMode>
);
