import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Col, Row } from "reactstrap";
import ValluLogoSvg from "../images/_Vallu_musta.svg";

export default function Saavutettavuusseloste() {
  const { t } = useTranslation("common");
  return (
    <>
      <main className="container-fluid">
        <section className="row">
          <Col />
          <Col xs={10}>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/">{t("frontPage")} </Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>{t("accessibility.link")}</BreadcrumbItem>
            </Breadcrumb>
            <h1>Saavutettavuusseloste</h1>
            <p>
              Tämä saavutettavuusseloste koskee Vallu-valintakoepalveluun
              liittyviä esimerkkitehtäviä osoitteessa testikoe.korkeakouluun.fi.
              Esimerkkitehtävien avulla voi tutustua erilaisiin
              tehtävätyyppeihin hakijan valintakoenäkymässä. Lisäksi
              esimerkkitehtävien avulla voi harjoitella tehtäviin vastaamista
              sekä laskimen käyttöä. Palvelun saavutettavuuden olemme arvioineet
              itse.
            </p>

            <h2>Palvelun saavutettavuuden tila </h2>
            <p>
              testikoe.korkeakouluun.fi-sivusto täyttää
              saavutettavuusvaatimukset osittain.
            </p>
            <h2>Saavutettavuuspuutteista ilmoittaminen </h2>
            <p>
              Palvelun saavutettavuudesta voi antaa palautetta sähköpostitse
              saavutettavuus@metropolia.fi.
            </p>
            <p>
              Sisällytä viestiin palvelun osoite
              &quot;testikoe.korkeakouluun.fi&quot;.
            </p>
            <h2>Valvontaviranomainen</h2>
            <p>
              Jos huomaat sivustolla saavutettavuusongelmia, anna ensin
              palautetta meille eli sivuston ylläpitäjälle. Vastauksessa voi
              mennä 14 päivää. Jos et ole tyytyväinen saamaasi vastaukseen tai
              et saa vastausta lainkaan kahden viikon aikana,{" "}
              <a
                rel="noreferrer"
                href="https://www.saavutettavuusvaatimukset.fi/kayttajan-oikeudet/"
                target="_blank">
                voit tehdä ilmoituksen Etelä-Suomen aluehallintovirastoon
              </a>
              . Etelä-Suomen aluehallintoviraston sivulla kerrotaan tarkasti,
              miten ilmoituksen voi tehdä ja miten asia käsitellään.
            </p>
            <h2>Valvontaviranomaisen yhteystiedot </h2>
            <p>
              Etelä-Suomen aluehallintovirasto <br />
              Saavutettavuuden valvonnan yksikkö <br />
              www.saavutettavuusvaatimukset.fi <br />
              saavutettavuus(at)avi.fi <br />
              puhelinnumero vaihde 0295 016 000
            </p>

            <h2>Ei-saavutettava sisältö</h2>
            <p>
              Seuraava sisältö ja/tai toiminnot eivät ole vielä vaatimusten
              mukaisia:{" "}
            </p>

            <h3>Tekstivastineet saattavat olla osin puutteellisia </h3>
            <h4>Saavuttamaton sisältö ja sen puutteet </h4>
            <p>
              Valintakokeissa saattaa olla kuvia, joilla ei ole alt-tekstiä.
            </p>
            <p>
              Tehtäviin saattaa sisältyä elementtejä, jotka eivät sellaisenaan
              ole saavutettavia ja siten sovellu ruudunlukijahakijoille.
            </p>
            <p>
              Valintakokeen tehtävistä tehdään tarvittaessa saavutettavat
              versiot, joissa on esimerkiksi kuvattomat tehtävät tai kokeen
              liiteaineisto saavutettavana Word- tai PDF-tiedostona. Näin
              toimitaan, jos kokeeseen osallistuu hakija, joka on yksilöllistenä
              järjestelynä saanut luvan käyttää ruudunlukijaa.
            </p>
            <h4>Saavutettavuusvaatimukset, jotka eivät täyty </h4>
            <p>1.1.1 Ei-tekstuaalinen sisältö</p>

            <h3>
              Kaikkea valintakokeiden sisältöä ei välttämättä voida esittää
              mukauttaen eri tavoilla{" "}
            </h3>
            <h4>Saavuttamaton sisältö ja sen puutteet </h4>
            <p>
              Tehtävien sisällön esitystavassa saattaa olla tilanteita, joissa
              informaatio, tiedon rakenne ja suhteet eivät välity täysin
              saavutettavasti. Tällainen tilanne voi tulla esiin esimerkiksi
              otsikointien, luetteloiden, taulukoiden ja PDF-liiteaineistojen
              kohdalla.
            </p>
            <p>
              Sivustolla ei ole käytetty &quot;&lt;main&lt;&quot;
              -HTML5-aluetta.
            </p>

            <h4>Saavutettavuusvaatimukset, jotka eivät täyty </h4>
            <p>1.3.1 Informaatio ja suhteet </p>

            <h3>
              Sivuston sisällön ja taustan erottuvuudessa ja tekstin tai näytön
              koon muuttamisessa saattaa olla puutteita{" "}
            </h3>
            <h4>Saavuttamaton sisältö ja sen puutteet </h4>
            <p>
              Tehtävien sisällön esitystavassa saattaa esiintyä tilanteita,
              joissa väriä on saatettu käyttää ainoana visuaalisena keinona
              välittämään informaatiota.
            </p>
            <p>
              Kielivalinta- ja Takaisin osioihin -linkkien kontrastissa on
              pieniä puutteita. Tehtävien sisällön esitystavassa saattaa
              esiintyä tilanteita, joissa kontrasti ei ole riittävä.
            </p>
            <p>
              Esseetehtävän tarkastele vastauksen aiempaa versiota -näkymässä
              Palauta- ja Peruuta-painikkeet katoavat näkyvistä, kun tekstin
              koko on suurennettu 200 %:n.
            </p>
            <p>
              Tehtäviin saattaa sisältyä elementtejä, jotka eivät sellaisenaan
              ole saavutettavia ja siten sovellu ruudunlukijahakijoille.
              Valintakokeen tehtävistä tehdään tarvittaessa saavutettavat
              versiot, jossa on esimerkiksi kuvattomat tehtävät. Näin toimitaan,
              jos kokeeseen osallistuu hakija, joka on yksilöllistenä
              järjestelynä saanut luvan käyttää ruudunlukijaa.
            </p>
            <p>
              Kapealla näytöllä pystyasennossa osa sisällöstä saattaa rajautua
              siten, että sisällön näkeminen edellyttää sivusuuntaista
              vieritystä. Esimerkkitehtävät mukailevat varsinaista
              valintakoetta. Hakijoille asetettujen järjestelmävaatimusten
              mukaan valintakoe suoritetaan omalla kannettavalla tietokoneella,
              joten myös esimerkkitehtäviin tutustumiseen suositellaan
              tietokonetta. Tietokoneella näkymä on vaaka-asennossa.
            </p>

            <h4>Saavutettavuusvaatimukset, jotka eivät täyty </h4>
            <ul>
              <li>1.4.1 Värien käyttö</li>
              <li>1.4.3 Kontrasti (minimi)</li>
              <li>1.4.4 Tekstin koon muuttaminen</li>
              <li>1.4.5 Tekstiä esittävät kuvat</li>
              <li>1.4.10 Responsiivisuus</li>
            </ul>

            <h3>Sivuston näppäimistökäytettävyydessä on puutteita </h3>
            <h4>Saavuttamaton sisältö ja sen puutteet </h4>
            <p>
              Laskinta ei saa avattua tai suljettua pelkästään näppäimistöä
              käyttäen. Laskutoimitukset saa osin syötettyä näppäimistöltä.
            </p>
            <p>
              Kaavaeditori ei ole näppäimistösaavutettava. Joitakin kaavoja voi
              syöttää pikakomennoilla näppäimistöä käyttäen. Kaavaeditorin saa
              auki CTRL+E-näppäinyhdistelmällä, ja sillä saa lisättyä joitain
              kaavoja. Järjestelmässä ei ohjeisteta kaavaeditorin
              näppäimistökäyttöä.
            </p>
            <h4>Saavutettavuusvaatimukset, jotka eivät täyty </h4>
            <ul>
              <li>2.1.1 Näppäimistö </li>
            </ul>

            <h3>Sivuston navigoitavuudessa saattaa olla puutteita </h3>
            <h4>Saavuttamaton sisältö ja sen puutteet </h4>
            <p>
              Sivustolla on Siirry suoraan sisältöön -linkki. Se ei näy
              näkevälle käyttäjälle, mutta vie ruudunlukijaa käyttävän käyttäjän
              näkymän pääsisältöön.
            </p>
            <p>
              Tehtävien sisällön esitystavassa saattaa olla puutteellisia
              otsikkorakenteita.
            </p>
            <p>
              Tehtävien sivunäkymillä ei ole omia sivuotsikoita. Sivuotsikko
              pysyy samana jokaisessa näkymässä. Muiden sivujen otsikoiden
              johdonmukaisuudessa voi olla puutteita tai kaikkia otsikoita ei
              ole merkitty ohjelmallisesti.
            </p>
            <p>
              Valintakokeiden sisältöön sisältyvien linkkien selkeydessä saattaa
              olla puutteita.
            </p>
            <p>Testikokeessa ei ole sisällysluetteloa tai sivukarttaa.</p>
            <p>
              Valintakokeiden sisältöön sisältyvien otsikoiden ja lomakkeiden
              nimilappujen selkeydessä saattaa olla puutteita.
            </p>
            <p>
              Näppäimistökohdistus on näkyvissä, mutta se ei ole välttämättä
              tarpeeksi selkeä heikkonäköiselle.
            </p>

            <h4>Saavutettavuusvaatimukset, jotka eivät täyty </h4>
            <ul>
              <li>2.4.1 Ohita lohkot </li>
              <li>2.4.2 Sivuotsikot </li>
              <li>2.4.4 Linkin tarkoitus (kontekstissa) </li>
              <li>2.4.5 Useita tapoja </li>
              <li>2.4.6 Otsikot ja nimilaput </li>
              <li>2.4.7 Näkyvä kohdistus </li>
            </ul>

            <h3>
              Tekstisisällön luettavuudessa ja ymmärrettävyydessä saattaa olla
              puutteita{" "}
            </h3>
            <h4>Saavuttamaton sisältö ja sen puutteet </h4>
            <p>
              testikoe.korkeakouluun.fi etusivun ja kaavaeditoriohjeiden sivun
              kieleksi on määritelty englanti. Lisäksi lopetussivun kieleksi on
              määritelty suomi, vaikka sivulla on tekstiä suomeksi ja ruotsiksi.
            </p>
            <p>
              Tehtäviin saattaa sisältyä elementtejä, jotka eivät sellaisenaan
              ole saavutettavia ja siten sovellu ruudunlukijahakijoille.
              Valintakokeeseen mahdollisesti sisältyvien eri kielillä
              kirjoitettujen osuuksien kieli määritellään erikseen käyttäen
              lang-attribuuttia, jos kokeeseen osallistuu hakija, joka on
              yksilöllistenä järjestelynä saanut luvan käyttää ruudunlukijaa.
            </p>
            <h4>Saavutettavuusvaatimukset, jotka eivät täyty </h4>
            <ul>
              <li>3.1.1 Sivun kieli </li>
              <li>3.1.2 Osien kieli </li>
            </ul>

            <h3>Sivustolla navigointi ei ole johdonmukaista </h3>
            <h4>Saavuttamaton sisältö ja sen puutteet </h4>
            <p>
              Testikoe.korkeakouluun.fi etusivun ja kaavaeditoritehtävien
              ohjeiden ja eroaa hiukan tehtäväsivujen rakenteesta, joten sivujen
              välillä navigointi ei ole johdonmukaista.
            </p>
            <h4>Saavutettavuusvaatimukset, jotka eivät täyty </h4>
            <ul>
              <li>3.2.3 Johdonmukainen navigointi </li>
            </ul>

            <h3>Sivuston yhteensopivuudessa saattaa olla puutteita </h3>
            <h4>Saavuttamaton sisältö ja sen puutteet </h4>
            <p>
              Sivusto sisältää jonkin verran virheellistä HTML/XHTML-koodia,
              mikä voi haitata ruudunlukuohjelmien tiedon jäsentämistä.
            </p>
            <p>Esseetehtävien vastauskentällä ei ole nimeä.</p>
            <p>
              Esseevastauksen aiempien versioiden tarkastelunäkymän ajasta
              kertovista painikkeista ei välity ohjelmallisesti tietoa siitä,
              onko painike aktiivinen/valittu.
            </p>
            <p>
              Näkevälle käyttäjälle tulee valinnoista ja toiminnoista
              (esimerkiksi monivalintatehtävien vastausvaihtoehtojen valinnasta
              tai esseetehtävän tallennuksesta) ilmoitus, että tieto on
              tallennettu. Ruudunlukijakäyttäjälle ei tule palautetta asiasta.
            </p>
            <h4>Saavutettavuusvaatimukset, jotka eivät täyty </h4>
            <ul>
              <li>4.1.1 Jäsentäminen </li>
              <li>4.1.2 Nimi, rooli, arvo </li>
              <li>4.1.3 Tilasta kertovat viestit </li>
            </ul>
            <div className="mb-5">
              <Link to="/">{t("toFrontPage")} </Link>
            </div>
          </Col>
          <Col />
        </section>
      </main>
      <footer className="container-fluid">
        <Row
          className="py-4"
          style={{ paddingTop: "3rem", paddingBottom: "3rem" }}>
          <Col className="h-100 align-self-end">
            <img
              alt=""
              className="img-fluid"
              src={ValluLogoSvg}
              style={{ height: "3rem" }}
            />
          </Col>
          <Col />
          <Col className="h-100 align-self-end text-end">
            <p className="mb-0">&copy; {new Date().getFullYear()} Metropolia</p>
          </Col>
        </Row>
      </footer>
    </>
  );
}
