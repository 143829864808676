import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Col, Row } from "reactstrap";
import ValluLogoSvg from "../images/_Vallu_musta.svg";

export default function Guide() {
  const { t } = useTranslation("common");
  return (
    <>
      <main className="container-fluid">
        <section className="row">
          <Col />
          <Col xs={10}>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/">{t("frontPage")} </Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>{t("guidePage")}</BreadcrumbItem>
            </Breadcrumb>
            <div className="mb-5">
              <h2 className="header2">{t("guide.introTitle")}</h2>
              <p>{t("guide.intro")}</p>
              <div className="d-flex justify-content-center image">
                <img
                  alt=""
                  className="img-fluid"
                  src={require(`../images/intro_${i18next.language}.png`)}
                />
              </div>
            </div>
            <div className="mb-5">
              <h2 className="header2">{t("guide.formsTitle")}</h2>
              <div className="d-flex justify-content-center image">
                <img
                  alt=""
                  className="img-fluid"
                  src={require(`../images/forms1_${i18next.language}.png`)}
                />
              </div>
              <p>{t("guide.forms")}</p>
              <div className="d-flex justify-content-center image">
                <img
                  alt=""
                  className="img-fluid"
                  src={require(`../images/forms2_${i18next.language}.png`)}
                />
              </div>
            </div>
            <div className="mb-5">
              <h2 className="header2">{t("guide.specialCharsTitle")}</h2>
              <div className="d-flex justify-content-center image">
                <img
                  alt=""
                  className="img-fluid"
                  src={require("../images/specialChars1.png")}
                />
              </div>
              <p>{t("guide.specialChars")}</p>
              <div className="d-flex justify-content-center image">
                <img
                  alt=""
                  className="img-fluid"
                  src={require(`../images/specialChars2_${i18next.language}.png`)}
                />
              </div>
            </div>
            <div className="mb-5">
              <h2 className="header2">{t("guide.writingTitle")}</h2>
              <p>{t("guide.writing")}</p>
              <div className="d-flex justify-content-center image">
                <img
                  alt=""
                  className="img-fluid"
                  src={require(`../images/writing_${i18next.language}.png`)}
                />
              </div>
            </div>
            <div className="mb-5">
              <h2 className="header2">{t("guide.editSavedTitle")}</h2>
              <p>{t("guide.editSaved1")}</p>
              <div className="d-flex justify-content-center image">
                <img
                  alt=""
                  className="img-fluid"
                  src={require(`../images/editSaved1.png`)}
                />
              </div>
              <p>{t("guide.editSaved2")}</p>
              <div className="d-flex justify-content-center image">
                <img
                  alt=""
                  className="img-fluid"
                  src={require(`../images/editSaved2.png`)}
                />
              </div>
            </div>
            <div className="mb-5">
              <h2 className="header2">{t("guide.exitEditTitle")}</h2>
              <p>{t("guide.exitEdit")}</p>
              <ul>
                <li>{t("guide.exitEdit1stBullet")}</li>
                <li>{t("guide.exitEdit2ndBullet")}</li>
                <li>{t("guide.exitEdit3rdBullet")}</li>
              </ul>
            </div>
            <div className="mb-5">
              <h2 className="header2">{t("guide.editorGuideTitle")}</h2>
              <p>{t("guide.editorGuide")}</p>
              <div className="d-flex justify-content-center image">
                <img
                  alt=""
                  className="img-fluid"
                  src={require("../images/questionMark.png")}
                />
              </div>
              <p>{t("guide.editorGuide2")}</p>
              <div className="d-flex justify-content-center image">
                <img
                  alt=""
                  className="img-fluid"
                  src={require(`../images/editorGuide_${i18next.language}.png`)}
                />
              </div>
            </div>
            <div className="mb-5">
              <h2 className="header2">{t("guide.saveTitle")}</h2>
              <p>{t("guide.save")}</p>
              <div className="d-flex justify-content-center image">
                <img
                  alt=""
                  className="img-fluid"
                  src={require(`../images/save_${i18next.language}.png`)}
                />
              </div>
            </div>
            <div className="mb-5">
              <Link to="/">{t("toFrontPage")} </Link>
            </div>
          </Col>
          <Col />
        </section>
      </main>
      <footer className="container-fluid">
        <Row className="py-4">
          <Col xs={6} className="order-1 order-sm-0 h-100 align-self-end">
            <img
              alt="Vallu logo"
              className="img-fluid"
              src={ValluLogoSvg}
              style={{ height: "3rem" }}
            />
          </Col>
          <Col xs={6} className="order-2 h-100 align-self-end text-end">
            <p className="mb-0">&copy; {new Date().getFullYear()} Metropolia</p>
          </Col>
        </Row>
      </footer>
    </>
  );
}
