import React from "react";

export default function ExerciseCard({
  imgSrc = null,
  title = "",
  description = ""
}) {
  return (
    <div
      style={{ maxHeight: "12rem" }}
      className="bg-white rounded-3 px-2 py-1 d-flex flex-column justify-content-between gap-3 h-100">
      {imgSrc ? (
        <img
          alt=""
          className="img-fluid px-2 py-2"
          style={{ height: "4rem" }}
          src={imgSrc}
        />
      ) : (
        ""
      )}
      <div
        className="rounded-3 px-2 py-2 text-center h-100 d-flex flex-column justify-content-between align-items-center"
        style={{ backgroundColor: "#E6E6E6" }}>
        <p className="fw-bold">{title}</p>
        <p className="m-0">{description}</p>
      </div>
    </div>
  );
}
